.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: center;
    width: 100%;
    overflow: hidden;
}

.table-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    font-size: 30px;
    line-height: 30px;
    padding: 2rem;
    width: 90vw;
}