.action-container {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-grow: 1;
    font-size: 30px;
    line-height: 10px;
    padding: 1px;
    width: 100%;
}

.claim-btn {
    width: 106px;
    height: 28px;
    left: 14px;
    top: 178px;
    background: #000;
    border-radius: 4px;
}

