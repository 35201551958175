.btn {
    cursor: pointer;
    outline: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 6px 12px;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.color-pink {
    background-color: #FF1791;
    color: #FFF;
}

.color-blue {
    background-color: #020BF5;
    color: #FFF;
}

.color-green {
    background-color: #00CC14;
    color: #FFF;
}

.color-red {
    background-color: #E2215A;
    color: #FFF;
}

.color-black {
    background-color: #000;
    color: #FFF;
}

.color-white {
    background-color: #FFF
}

.color-grey {
    background-color: #F5F6F8
}

.color-baby-poo-yellow{
    background-color: #E9FD6E;
}

.color-transparent {
    background-color: transparent;
}