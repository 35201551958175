.tick-box {
  display: flex;
  justify-content: center;
  align-items: center;

  /* Outline */
  box-sizing: border-box;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
}

.tick-box-on {
  /* Green */
  background: #4fbf34;
}

.sm {
  width: 12px;
  height: 12px;
}

.lg {
  width: 18px;
  height: 18px;
}
