.footer {
    width: 100%;
    position: sticky;
    bottom: 0;
    background-color: #FFF;
    border: 1px solid #DBDEE3;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container > * {
    margin: 0.5rem;
}

.page-btn {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 6px;
}

.double-chevron-transform {
    transform: rotate(180deg);
}