.root {
    height: 54px;
    z-index: 10;
    border: 1px solid #F5F6F8;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.right-pane {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
    right: 0;
    position: absolute;
}

.item {
    font-weight: 600;
    font-size: 12px;
    color: inherit;
    text-decoration: none;
    height: 3rem;
    padding: 2rem 1.4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    width: 18rem;
    transition: all 0.2s ease-in-out;
}
