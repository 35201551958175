.container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F6F8;
}

.paragraph {
    font-size: 1rem;
}