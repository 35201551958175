.container {
    border-radius: 40px;
}

.pause-container {
    background: #F5F6F8;
}

.resume-container {
    background: #000;
}

.icon-margin {
    margin-right: 4px;
}

.monitor-btn {
    border-radius: 40px;
    font-size: 11px;
    flex-direction: row;
    width: 4.875rem;
    gap: 0.25rem;
}

.pause-btn {
    color: #141823;
}