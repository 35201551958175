.container {
  display: flex;
  align-items: center;
  padding: 1rem;
  flex-flow: row wrap;
  justify-content: space-between;
}

.filter-container {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.source-btn {
  border-radius: 2.5rem;
  border: 2px solid #eef0f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  margin: 0.5rem;
}

.event-type-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 4.125rem;
  height: 1.75rem;
}

.source-btn-on {
  border-color: #00cc14;
}

.dropdown-btn-container {
  margin: 0.5rem;
}

.source-dropdown-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #232d3f;
  border-radius: 2.5rem;
  width: 5.7rem;
  height: 1.75rem;
  padding: 0.25rem;
}

.highlighted-count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.25rem;
  background: #232d3f;
  border-radius: 2.5rem;
  color: #fff;
}

.source-logo {
  width: 1.125rem;
  margin-left: 0.125rem;
}

.dropdown-source-logo {
  width: 1.375rem;
  height: 1.375rem;
  border: 2px solid #4fbf34;
  border-radius: 50%;
}

.source-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.dropdown-menu {
  box-sizing: border-box;
  position: relative;
  width: 14rem;
  height: 13.625rem;
  background: #fff;
  border: 1px solid #dbdee3;
  box-shadow: 0 6px 12px 1px rgb(0 0 0 / 12%);
  border-radius: 6px;
  overflow: auto;
}

.dropdown-item {
  color: #232d3f;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.dropdown-source-select-all {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
}

.color-dot {
  display: inline-block;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
}

.tag-desc {
  display: flex;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
}

.checkbox {
  margin-left: auto;
}

.btn-with-icon {
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  gap: 0.188rem;
}

.source-search-field {
  margin: 0.75rem;
  width: 90%;
  height: 2rem;
  background-color: #FFF;
}

.source-dropdown-menu {
  width: 286px;
  height: 288px;
}