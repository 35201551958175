.reject-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 40px;
  gap: 10px;
}

.up-btn {
  justify-content: space-around;
  background: #eef0f2;
  border: 1px solid #eef0f2;
  border-radius: 0 4px 4px 0;
  height: 28px;
  width: 49px;
}

.price-roll-input {
  flex-direction: row;
  box-sizing: border-box;
  border: 1px solid #eef0f2;
  min-width: 48px;
  height: 28px;
  padding: 5px;
  background-color: transparent;
}

.price-roll-container {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: 150px;
}

.down-btn {
  background: #eef0f2;
  border: 1px solid #eef0f2;
  border-radius: 4px 0 0 4px;
  height: 28px;
  width: 49px;
}

.money-btn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 10%;
  background: #eef0f2;
  border-radius: 4px;
}

.win-btn {
  background: #eef0f2;
  border: 1px solid #eef0f2;
  border-radius: 4px 0 0 4px;
}

.win-input {
  border: 1px solid #eef0f2;
  border-radius: 0 4px 4px 0;
  min-width: 48px;
  height: 28px;
  padding: 5px;
  background-color: transparent;
}

.stake-btn {
  background: #eef0f2;
  border: 1px solid #eef0f2;
  border-radius: 4px 0 0 4px;
}

.stake-input {
  border: 1px solid #eef0f2;
  border-radius: 0 4px 4px 0;
  min-width: 48px;
  height: 28px;
  padding: 5px;
  background-color: transparent;
}

.to-win-container {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 10px;
}

.win-container {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: fit-content;
}

.stake-container {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}

.accept-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 6px 40px;
  gap: 10px;
}

.claimed-action-container {
  display: flex;
  justify-content: right;
  gap: 10px;
  align-items: center;
  height: 49px;
  width: 50%;
}

.container {
  display: flex;
  justify-content: space-between;
}

.comments-section {
  display: flex;
  flex-direction: column;
  padding-left: 0.25rem;
  align-items: flex-start;
  gap: 1rem;
  width: 50%;
}

.comment-details {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: left;
  align-items: baseline;
  width: 100%;
}

.commenter {
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.comment-time {
  font-family: Inter, sans-serif;
  color: #7B8294;
  font-size: 12px;
}

.comments-button {
  border-radius: 40px;
}

.comments {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.comment-content {
  font-size: 14px;
  font-family: Inter, sans-serif;
  color: #465169;
}

.comment-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}