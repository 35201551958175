.toggle-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.toggle-half {
    min-height: 5px;
    display: flex;
}

.sortable {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 0.25rem;
    padding: 0;
    width: 100%;
}
