.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 66%;
  border: 1px solid #DBDEE3;
  border-radius: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 454px;
  width: 372px;
  padding: 12px 38px;
  gap: 16px;
}

.form-title {
  font-size: 30px;
  line-height: 38px;
}

.form-intro {
  color: #465169;
}

.form-label {
  color: #7B8294;
}

.form-input {
  /* White */
  background: #FFF;

  /* Borders/borderPrimary */
  border: 1px solid #DBDEE3;

  /* Shadow/xs */
  box-shadow: 0 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
}

.btn {
  width: 100%;
}

.sso-btn {
  background-color: #E9FD6E;
}

.sso-label {
  color: #465169;
}