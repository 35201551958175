.link {
    color: black;
    text-decoration: none;
    border: none;
    background-color: transparent;
    padding: 0 1.25rem;
    margin: 0 0.25rem;
    border-radius: 0.25rem;
    min-height: 2rem;
    line-height: 2rem;
}

.link-no-pop {
    color: black;
}

.link-pop {
    position: relative;
    background: #fff;
    border: 0.05rem solid #e2215a99;
    padding: 0 1.5rem 0 1.25rem;
}

.link-pop::after {
    animation: link-pop-animation 0.5s ease-out infinite;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 0.25rem;
}

.link-pop::before {
    content: "";
    border-radius: 100%;
    background: #e2215aff;
    width: 0.35rem;
    height: 0.35rem;
    top: 0.8rem;
    right: 0.75rem;
    position: absolute;
}
  
@keyframes link-pop-animation {
    0% {
        box-shadow: 0 0 0 0 #e2215aff;
    }

    100% {
        box-shadow: 0 0 0.25rem 0.25rem #e2215a00;
    }
}

.selected {
    background-color: #F5F6F8;
}

.link:hover {
    background-color: #F5F6F8;
    color: black;
}