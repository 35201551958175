.container {
    display: flex;
    align-items: center;
    padding: 0 1rem;
}

.item {
    padding: 0 0.375rem;
}

.day {
    font-size: 0.875rem;
    line-height: 1rem;
    color: #465169;
}

.time {
    font-size: 1.25rem;
    line-height: 1.25rem;
}