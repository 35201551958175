.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: center;
    width: 100%;
    overflow: hidden;
}

.table-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    font-size: 30px;
    line-height: 30px;
    padding: 2rem;
    width: 90vw;

}

.claimed-header {
    background-color: #141823;
    flex-shrink: 0;
    height: 48px;
    color: #DBDEE3;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 20px;

}

.table-body, .table-container {
    background: #f6f7f8;
}


.claimed-close {
    display: flex; justify-content: flex-end;
    margin-left: auto;
}


.claimed-counter {
    background: rgb(89 89 89 / 60%);
    border-radius: 14px;
}
