.container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 0.375rem;
  background-color: #f5f6f8;
  border: 1px solid #eef0f2;
  border-radius: 0.25rem;
  width: 100%;
  height: 1.625rem;
  padding: 0.625rem;
}

.input {
  border: none;
  border-radius: inherit;
  background-color: transparent;
  width: 100%;
  padding: 0;
}

.input:focus {
  outline: none;
}

.show-password-btn {
  background-color: transparent;
}