.main {
  border-collapse: collapse;
  table-layout: fixed;
  width: fit-content;
}

.column-resizer {
  background: transparent;
  width: 3px;
  border-right: 1px grey;
  padding: 0 !important;
  cursor: ew-resize;
}

tr > .column-resizer:not(:first-child) {
  border-left: 1px solid #dbdee3;
}

.search-field {
  margin-top: 0.5rem;
}

.event-field-capitalize {
  text-transform: capitalize;
}

.header-row {
  position: sticky;
  top: 0;
  z-index: 1;
  background-clip: padding-box;
  border-bottom: 1px solid #f5f6f8;
}

th,
td:not(.column-resizer) {
  padding: 0.5rem;
  text-align: left;
}

th {
  vertical-align: top;
  font-weight: 600;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.clickable {
  cursor: pointer;
}

.header-row > th {
  min-width: 3rem;
  padding-left: 0.5rem;
}

.row,
.header-row {
  border-collapse: collapse;
}

.row > td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0.5rem;
  padding-left: 0.5rem;
}

.row:nth-child(odd) > td {
  background: #f5f6f8;
  background-clip: padding-box;
}

.row-color-red td:not(:nth-child(29), :nth-child(28)) {
  background: #e2215a;
  color: #fff;
}

.row-color-blue td:not(:nth-child(29), :nth-child(28)) {
  background: #3489EB;
  color: #fff;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: scroll;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  height: 50%;
}

thead {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.source-logo {
  width: 1.125rem;
  margin-left: 0.125rem;
}

.win-loss-negative {
  color: #4fbf34;
}

.win-loss-positive {
  color: #e2215a;
}

.bonus-bet {
  font-weight: bold;
  background-color: #0ff !important;
}


.sort-btn {
  padding: 0;
}